import React from 'react';
import { Link, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import styled from 'styled-components';

import Head from 'components/head';
import Layout from 'components/layout';

import Section from '~v2/section';
import Wrapper from '~v2/elements/container/wrapper';
import Title from '~v2/elements/title';
import { TitleContainer } from '~v2/elements/title/title.css';
import Copy from '~v2/elements/copy';
import { colors } from 'constants/theme';

import { MarkdownContainer, MarkdownContent, Pagination } from 'blog.css.js';

class WordPressBlogPostTemplate extends React.Component {
  render() {
    const {
      data: {
        wordpressPost: post,
        site: {
          siteMetadata: { title: siteTitle },
        },
      },
      pageContext: { next, previous },
      location,
    } = this.props;

    const featuredImg =
      post.featured_media &&
      post.featured_media.localFile &&
      post.featured_media.localFile !== null &&
      post.featured_media.localFile.childImageSharp
        ? post.featured_media.localFile.childImageSharp.fluid
        : false;

    return (
      <Layout location={location} title={siteTitle}>
        <Head
          pageTitle={post.title}
          pageDescription={post.excerpt}
          imageUrl={featuredImg.src}
        />
        <Wrapper>
          <TitleContainer margin="10rem 0 1rem">
            <Title as="h1">
              <span dangerouslySetInnerHTML={{ __html: post.title }} />
            </Title>
            <Copy marginBottom="0">
              {post.date && `Published ${post.date}`}
              <br />
              {post.categories &&
                ` in ${post.categories.map(category => ` ${category.name}`)}`}
            </Copy>
          </TitleContainer>
          <Section
            boundsProps={{
              fluid: true,
              style: { width: '100%' },
              DESKTOP_DOWN: { padding: '0 2rem' },
              DESKTOP: { padding: '0 2rem' },
            }}
            left={{
              boxProps: {
                fluid: true,
                TABLET_DOWN: { width: '0%' },
                DESKTOP: { width: '2.5%' },
                LG_DESKTOP: { width: '15%' },
              },
              backgroundProps: {
                bgColor: colors.collective_red,
              },
            }}
            right={{
              children: (
                <MarkdownContainer>
                  {featuredImg && !post.acf.hide_featured_on_post && (
                    <FeaturedImageContainer>
                      <Img fluid={featuredImg} alt={post.title} />
                    </FeaturedImageContainer>
                  )}
                  <MarkdownContent
                    dangerouslySetInnerHTML={{
                      __html: post.content,
                    }}
                  />
                  <Pagination>
                    <li>
                      {previous && (
                        <Link to={previous.fields.slug} rel="prev">
                          ← {previous.frontmatter.title}
                        </Link>
                      )}
                    </li>
                    <li>
                      <Link to="/blog/">back to blog</Link>
                    </li>
                    <li>
                      {next && (
                        <Link to={next.fields.slug} rel="next">
                          {next.frontmatter.title} →
                        </Link>
                      )}
                    </li>
                  </Pagination>
                </MarkdownContainer>
              ),
              boxProps: {
                fluid: true,
                shiftUp: true,
                TABLET_DOWN: { width: '100%' },
                TABLET: { padding: '2rem' },
                DESKTOP: { width: '97.5%' },
                LG_DESKTOP: { width: '85%' },
              },
            }}
          />
        </Wrapper>
      </Layout>
    );
  }
}

WordPressBlogPostTemplate.propTypes = {
  data: PropTypes.shape({
    wordpressPost: PropTypes.shape({}),
    site: PropTypes.object,
    siteMetadata: PropTypes.shape({
      title: PropTypes.string,
    }),
  }).isRequired,
  pageContext: PropTypes.shape({
    previous: PropTypes.shape({
      fields: PropTypes.shape({
        slug: PropTypes.string,
      }),
      frontmatter: PropTypes.shape({
        title: PropTypes.string,
      }),
    }),
    next: PropTypes.shape({
      fields: PropTypes.shape({
        slug: PropTypes.string,
      }),
      frontmatter: PropTypes.shape({
        title: PropTypes.string,
      }),
    }),
  }),
  location: PropTypes.shape({}),
  copy: PropTypes.string,
  image: PropTypes.object,
};

export default WordPressBlogPostTemplate;

const FeaturedImageContainer = styled.div`
  margin-bottom: 3rem;
`;

export const pageQuery = graphql`
  query WordPressPostBySlug($slug: String!) {
    site {
      siteMetadata {
        siteTitle
      }
    }
    wordpressPost(slug: { eq: $slug }) {
      wordpress_id
      excerpt
      title
      content
      date(formatString: "MMMM DD, YYYY")
      categories {
        name
        slug
      }
      featured_media {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1280, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      acf {
        hide_featured_on_post
      }
    }
  }
`;
